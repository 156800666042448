import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <h2>Oops!</h2>
      <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
      <p>
        But you can still take a look at 
            {" "}
        <Link to='/blog'>
            my blog
        </Link>
            {" "}
            or
            {" "}
        <Link to="/contact">
              contact me.
        </Link> 
    </p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
